import * as React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Box,
} from "@chakra-ui/react";

import { ContentContainer } from "../components";
import { MainLayout } from "../layouts/Main";

const questionAnswerPairs = [
  {
    question: "Where does the nutrient data come from?",
    answer:
      "The USDA National Nutrient Database is the origin of the nutrient information",
  },
  {
    question:
      "Does the non-edible part of food count towards nutrient values and servings?",
    answer:
      "The calories, macronutrients, and servings listed for each food only apply to the part that is edible, meaning the part that does not contain refuse.",
  },
];

const FAQPage = () => {
  return (
    <MainLayout
      title="Frequently Asked Questions (FAQ) | Basilium"
      description="Check the most Frequently Asked Questions about Basilium"
    >
      <ContentContainer>
        <Heading as="h1" mb="12">
          Frequently Asked Questions (FAQ)
        </Heading>
        <Accordion allowMultiple>
          {questionAnswerPairs.map(({ question, answer }) => (
            <AccordionItem>
              <Heading size="xs" as="h2">
                <AccordionButton
                  px={0}
                  _hover={{
                    bg: "gray.50",
                  }}
                >
                  <Box flex="1" textAlign="left" fontWeight="bold">
                    {question}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Heading>
              <AccordionPanel pb={4} px={0}>
                {answer}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </ContentContainer>
    </MainLayout>
  );
};

export default FAQPage;
